<template>
  <div>
    <b-form-checkbox
      v-model="state"
      :value="true"
      :unchecked-value="false"
      v-on:input="$emit('input', $event)"
      v-on:change="$emit('change', $event)"
      ><slot
    /></b-form-checkbox>
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean
  },
  data() {
    return {
      state: false
    };
  },
  created() {
    this.state = this.value;
  },
  methods: {},
  watch: {
    value: function() {
      this.state = this.value;
    }
  }
};
</script>
