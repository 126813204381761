<template>
  <b-col xs="1" sm="2" md="3" lg="3" xxl="4" class="text-center maxWidth45">
    <a @click="select">
      <img
        v-if="thumbnailUrl"
        :src="thumbnailUrl"
        class="img-fluid thumbnail"
      />
      <img
        v-else
        src="@/assets/product-placeholder.png"
        class="img-fluid thumbnail"
      />
    </a>
    <p class="text-truncate text-nowrap productTitle pt-1">{{ title }}</p>
    <!-- TODO: add functionality for removing popdots from a package -->
    <b-input-group class="mt-3" v-if="this.isPositioned">
      <b-button @click="removePopdot">-</b-button>
      <b-form-input>0</b-form-input>
      <b-button>+</b-button>
    </b-input-group>
  </b-col>
</template>

<script>
import config from "@/config.js";
import axios from "axios";
import { mapGetters } from "vuex";

// Packaging    : is this card a packaging card?
// isPositioned : whether or not the popdot needs to spawn inside a package.
// position     : where to spawn inside packaging
export default {
  name: "ProductCard",
  props: {
    product: String,
    idToken: String,
    packaging: Boolean,
    position: Number,
    isPositioned: Boolean,
    reference: String
  },
  data() {
    return {
      title: "Product",
      thumbnailUrl: undefined,
      productUID: undefined,
      referenceProduct: undefined
    };
  },
  mounted() {
    // Fetch required product data
    axios
      .post(`${config.apiUrl}database/getProductData`, {
        idToken: this.idToken,
        product: this.product,
        props: ["thumbnailUrl", "title", "referenceProduct"],
        dashboardUID: this.appConfig.dashboardUID
      })
      .then(result => {
        const { title, thumbnailUrl, referenceProduct } = result.data;
        this.title = title;
        this.thumbnailUrl = thumbnailUrl;
        //this.productUID = productUID;
        this.referenceProduct = referenceProduct;
      })
      .catch(() => {});
  },
  methods: {
    select: function(e) {
      e.preventDefault();
      const productToLoad =
        this.referenceProduct === undefined
          ? this.product
          : this.referenceProduct;
      if (!this.packaging) {
        this.$parent.$parent.$parent.$parent.$parent.loadNewPopdotAtPosition(
          productToLoad,
          this.isPositioned
        );
        this.$store.commit("loadedProduct", productToLoad);
      } else {
        //console.log("shouldLoadNewProduct");
        this.$store.commit("packageLoaded", true);
        this.$parent.$parent.$parent.$parent.$parent.loadNewProduct(
          productToLoad
        );
      }
    },
    removePopdot: function(e) {
      e.preventDefault();
      const productToLoad =
        this.referenceProduct === undefined
          ? this.product
          : this.referenceProduct;
      this.$parent.$parent.$parent.$parent.$parent.removePopdotFromQueue(
        productToLoad
      );
    }
  },
  computed: {
    ...mapGetters(["appConfig", "loadedProduct"])
  }
};
</script>

<style lang="scss" scoped>
@import "@/custom.scss";
.thumbnail {
  border-radius: 0.4em;
}

.productTitle {
  font-family: $product-font;
  font-size: $product-font-size;
  color: $product-font-colour;
}

.maxWidth45 {
  max-width: 45% !important;
}

.selectorButton {
  min-width: 2.4em;
  margin-left: 0.2em;
}
</style>
