<template>
  <div>
    <!--Colours, should only be viewable with products that can take that colour-->
    <!-- for each propertyDrawer, make a category, 
    and then a checkbox for each key in propertyDrawer,
    which changes the value of key in propertyDrawer, 
    which gets stored in the database of that product-->

    <!-- Fillings -->
    <div>
      <h5 id="category-title">Choose filling</h5>
      <div class="d-flex flex-row">
        <ThumbnailCheckbox
          title="No filling"
          class="mr-2"
          @change="propChange('filling', 'none')"
          v-model="selectedProperties.filling.none"
        >
          <img
            src="@/assets/popdotconfig/geen keuze.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox
          title="Custard"
          class="mr-2"
          @change="propChange('filling', 'custard')"
          v-model="selectedProperties.filling.custard"
        >
          <img
            src="@/assets/popdotconfig/custard filling.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox
          title="Red fruit"
          class="mr-2"
          @change="propChange('filling', 'redfruit')"
          v-model="selectedProperties.filling.redfruit"
        >
          <img
            src="@/assets/popdotconfig/red fruit filling.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
      </div>
      <div class="d-flex flex-row">
        <ThumbnailCheckbox
          title="Chocolate"
          class="mr-2"
          @change="propChange('filling', 'chocolate')"
          v-model="selectedProperties.filling.chocolate"
        >
          <img
            src="@/assets/popdotconfig/choco filling.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox :spacer="true"></ThumbnailCheckbox>
        <ThumbnailCheckbox :spacer="true"></ThumbnailCheckbox>
      </div>
    </div>

    <!-- Coatings -->
    <div>
      <h5 id="category-title">Choose coating</h5>
      <div class="d-flex flex-row">
        <ThumbnailCheckbox
          title="No coating"
          class="mr-2"
          @change="
            resetDeco('deco');
            propChange('coating', 'none');
            select(false, -1);
          "
          v-model="selectedProperties.coating.none"
        >
          <img
            src="@/assets/popdotconfig/popdot.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox
          title="Chocolate"
          class="mr-2"
          @change="
            propChange('coating', 'chocolate');
            select(false, -1);
          "
          v-model="selectedProperties.coating.chocolate"
        >
          <img
            src="@/assets/popdotconfig/Popdot_choco.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox
          title="White"
          class="mr-2"
          @change="
            propChange('coating', 'white');
            select(false, -1);
          "
          v-model="selectedProperties.coating.white"
        >
          <img
            src="@/assets/popdotconfig/popdot_wit.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
      </div>
      <div class="d-flex flex-row">
        <ThumbnailCheckbox
          title="Pink"
          class="mr-2"
          @change="
            propChange('coating', 'pink');
            select(false, -1);
          "
          v-model="selectedProperties.coating.pink"
        >
          <img
            src="@/assets/popdotconfig/popdot_roze.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox :spacer="true"></ThumbnailCheckbox>
        <ThumbnailCheckbox :spacer="true"></ThumbnailCheckbox>
      </div>
    </div>
    <!-- Deco -->
    <div>
      <h5 id="category-title">Choose deco</h5>
      <div class="d-flex flex-row">
        <ThumbnailCheckbox
          title="No deco"
          class="mr-2"
          @change="
            propChange('deco', 'none');
            select(true, -1);
          "
          v-model="selectedProperties.deco.none"
        >
          <img
            src="@/assets/popdotconfig/geen keuze.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox
          title="hazelnuts"
          class="mr-2"
          v-bind:disabled="selectedProperties.coating.none"
          @change="
            propChange('deco', 'hazelnut');
            select(true, 0);
          "
          v-model="selectedProperties.deco.hazelnut"
        >
          <img
            src="@/assets/popdotconfig/hazelnut.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox
          title="caramel"
          class="mr-2"
          :disabled="selectedProperties.coating.none"
          @change="
            propChange('deco', 'caramel');
            select(true, 1);
          "
          v-model="selectedProperties.deco.caramel"
        >
          <img
            src="@/assets/popdotconfig/caramel.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
      </div>
      <div class="d-flex flex-row">
        <ThumbnailCheckbox
          title="sprinkles"
          class="mr-2"
          :disabled="selectedProperties.coating.none"
          @change="
            propChange('deco', 'sprinkles');
            select(true, 2);
          "
          v-model="selectedProperties.deco.sprinkles"
        >
          <img
            src="@/assets/popdotconfig/sprinkles.png"
            class="img-fluid thumbnail"
            draggable="false"
          />
        </ThumbnailCheckbox>
        <ThumbnailCheckbox :spacer="true"></ThumbnailCheckbox>
        <ThumbnailCheckbox :spacer="true"></ThumbnailCheckbox>
      </div>
    </div>
    <div class="border-top py-4">
      <p>
        <strong
          >Ask your account manager for the minimum order quantity
          (MOQ).</strong
        >
      </p>
      <b-button class="w-100 confirm-button" @click="savePopdot"
        >Add your custom PopDot!</b-button
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapGetters } from "vuex";
import CheckboxInput from "@/components/Inputs/CheckboxInput.vue";
import ThumbnailCheckbox from "@/components/Inputs/ThumbnailCheckbox.vue";
import ProductCard from "@/components/Dashboard/ProductCard";
//import config from "@/config.js";
//import axios from "axios";

export default {
  name: "PopdotPropertiesTab",
  components: { CheckboxInput, ThumbnailCheckbox, ProductCard },
  props: {
    productData: Object,
  },
  data() {
    return {
      title: "",
      description: "",
      popdotProperties: {},
      selectedCategory: "",
      selectedVariant: -1,
      selectedProperties: {
        filling: {
          none: true,
          custard: false,
          redfruit: false,
          chocolate: false,
        },
        coating: {
          none: true,
          chocolate: false,
          white: false,
          pink: false,
        },
        deco: {
          none: true,
          hazelnut: false,
          caramel: false,
          sprinkles: false,
        },
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.preparePopdotProperties();
  },
  methods: {
    preparePopdotProperties: function () {
      for (let propertyDrawer in this.productProperties) {
        this.popdotProperties[propertyDrawer] = [];
        this.productProperties[propertyDrawer].forEach((key) => {
          this.popdotProperties[propertyDrawer].push({
            propertyName: key,
            value: false,
          });
        });
      }
      this.$forceUpdate();
    },

    savePopdot: function (key, value) {
      //const popdotToSave = this.loadedProduct;
      console.log("saving popdot!", this.loadedProduct);
      /*axios.post(`${config.apiUrl}database/updateProductData`, {
        idToken: this.user.idToken,
        product: this.loadedProduct,
        key: `popdotProperties`,
        value: this.popdotProperties,
        dashboardUID: this.appConfig.dashboardUID
      });*/
      console.log("Editor update", key, value);
      this.$forceUpdate();
      this.$store.commit("customizedPopdot", this.loadedProduct);
      this.$parent.$parent.changeTab(0);
    },

    propChange: function (category, property) {
      for (const key in this.selectedProperties[category]) {
        this.selectedProperties[category][key] = key === property;
        if (category === "coating") this.selectedCategory = property;
      }
      //load product choices which will be a matrix.
    },
    select: function (variant, index) {
      //e.preventDefault();
      //Do we load a variant, or the baseProduct?
      let productToLoad;
      //if (variant && index !== -1) this.selectedVariant = index;
      if (variant) {
        if (index === -1) {
          productToLoad = this.productMatrix[this.selectedCategory].baseProduct;
        } else {
          this.selectedVariant = index;
          productToLoad = this.productMatrix[this.selectedCategory].variants[
            this.selectedVariant
          ];
        }
      } else {
        if (this.selectedVariant !== -1) {
          productToLoad = this.productMatrix[this.selectedCategory].variants[
            this.selectedVariant
          ];
        } else {
          productToLoad = this.productMatrix[this.selectedCategory].baseProduct;
        }
      }
      this.$parent.$parent.$parent.$parent.loadNewProduct(productToLoad);
      if (this.packageIsLoaded) this.$store.commit("packageLoaded", false);
      this.$store.commit("loadedProduct", productToLoad);
    },
    resetDeco: function (category) {
      this.selectedVariant = -1;
      let first = true;
      for (const key in this.selectedProperties[category]) {
        if (first) {
          this.selectedProperties[category][key] = true;
          first = false;
        } else {
          this.selectedProperties[category][key] = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "appConfig",
      "user",
      "productProperties",
      "loadedProduct",
      "productMatrix",
      "packageLoaded",
    ]),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.confirm-button {
  background-color: #9cc997;
  border-color: #9cc997;
}
</style>
